import Swiper from 'swiper';
import { Navigation, Scrollbar, Pagination, Controller } from 'swiper/modules';
import GLightbox from 'glightbox';

window.addEventListener('DOMContentLoaded', function () {

  /* lightbox */

  const lightbox = GLightbox();

  /*  modal */

  const modal = new HystModal({
    linkAttributeName: 'data-hystmodal',
    catchFocus: true,
    waitTransitions: true,
    closeOnEsc: true,
    backscroll: false,
  });

  /* menu */

  const menu = document.querySelector('.js-menu')

  if (menu) {
    const menuToggle = document.querySelector('.js-menu-toggle')
    const menuLinks = menu.querySelectorAll('a')

    menuToggle.addEventListener('click', function () {
      if (this.classList.contains('is-active')) {
        closeMenu()
      } else {
        openMenu()
      }
    })

    for (let i = 0; i < menuLinks.length; i++) {
      menuLinks[i].addEventListener('click', closeMenu)
    }

    function closeMenu() {
      document.body.classList.remove('no-scroll')
      document.querySelector('.wrapper').classList.remove('no-scroll')

      menuToggle.setAttribute('aria-expanded', false)
      menuToggle.classList.remove('is-active')
      menu.classList.remove('is-active')

      menu.addEventListener('transitionend', function () {
        menu.classList.add('is-closed')
      }, {
        once: true
      })

      document.removeEventListener('keyup', pressEsc)
    }

    function openMenu() {
      document.body.classList.add('no-scroll')
      document.querySelector('.wrapper').classList.add('no-scroll')

      menuToggle.setAttribute('aria-expanded', true)
      menuToggle.classList.add('is-active')
      menu.classList.remove('is-closed')

      setTimeout(() => {
        menu.classList.add('is-active'), 20
      });

      document.addEventListener('keyup', pressEsc)
    }

    function pressEsc(e) {
      if (e.key === 'Escape') {
        closeMenu()
      }
    }
  }

  /* header-banner */

  let headerBannerWrapper = document.querySelector('.header__banner--desktop')

  if (headerBannerWrapper) {

    let headerBannerToggle = headerBannerWrapper.querySelectorAll('.header-tabs__toggle')

    headerBannerToggle.forEach(tabToggle => {
      tabToggle.addEventListener('mouseover', () => showBanner(tabToggle))
      tabToggle.addEventListener('focus', () => showBanner(tabToggle))
    })

    function showBanner(tab) {
      let targetImage = tab.dataset.target
      headerBannerWrapper.querySelector('.header__banner-images .is-active').classList.remove('is-active')
      document.querySelector(`#${targetImage}`).classList.add('is-active')
    }
  }

  /* slides */

  const headerBanners = new Swiper('.header-slider', {
    modules: [Navigation, Scrollbar, Pagination, Controller],
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper .swiper-button-next',
      prevEl: '.swiper .swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  const cardSliders = new Swiper('.slider--type--cards .swiper', {
    modules: [Navigation, Scrollbar, Controller],
    initialSlide: 1,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper .swiper-button-next',
      prevEl: '.swiper .swiper-button-prev',
    },
    scrollbar: {
      el: '.swiper .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 'auto',
      }
    }
  });

  const eventsSlider = new Swiper('.slider--type--events .swiper', {
    modules: [Navigation, Scrollbar, Controller],
    slidesPerView: 1,
    spaceBetween: 15,
    navigation: {
      nextEl: '.swiper .swiper-button-next',
      prevEl: '.swiper .swiper-button-prev',
    },
    scrollbar: {
      el: '.swiper .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      576: {
        slidesPerView: 'auto',
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 0
      }
    }
  });

  const mediaSlider = new Swiper('.slider--type--media .swiper', {
    modules: [Navigation, Scrollbar, Controller],
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
      nextEl: '.swiper .swiper-button-next',
      prevEl: '.swiper .swiper-button-prev',
    },
    scrollbar: {
      el: '.swiper .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      576: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 1,
      }
    }
  });

  const photoSlider = new Swiper('.slider--type--photo .swiper', {
    modules: [Navigation, Scrollbar, Controller],
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper .swiper-button-next',
      prevEl: '.swiper .swiper-button-prev',
    },
    scrollbar: {
      el: '.swiper .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      998: {
        slidesPerView: 4,
      }
    }
  });

  const photoSliderTeam = new Swiper('.photo-slider .swiper', {
    modules: [Navigation, Controller, Scrollbar, Pagination],
    initialSlide: 1,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.photo-slider .swiper-button-next',
      prevEl: '.photo-slider .swiper-button-prev',
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 'auto',
      }
    }
  });

  const mediaDetailSlider = new Swiper('.slider--type--media-detail .swiper', {
    modules: [Navigation, Controller, Scrollbar, Pagination],
    initialSlide: 1,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.slider--type--media-detail .swiper-button-next',
      prevEl: '.slider--type--media-detail .swiper-button-prev',
    },
    scrollbar: {
      el: '.slider--type--media-detail .swiper-scrollbar',
      draggable: true,
    },
  });

  /* timeline */

  const timelineSlider1 = new Swiper(".slider-timeline-1 .swiper", {
    modules: [Navigation, Controller],
    slidesPerView: "auto",
    autoHeight: true,
    speed: 500,
    navigation: {
      nextEl: ".slider-timeline-1 .swiper-button-next",
      prevEl: ".slider-timeline-1 .swiper-button-prev"
    },
    loop: false,
    on: {
      init: function () {
        document.querySelector('.slider-timeline-1 .swiper-pagination-custom .swiper-pagination-switch').classList.remove("active");
        document.querySelectorAll('.slider-timeline-1 .swiper-pagination-custom .swiper-pagination-switch')[0].classList.add("active");
      },
      slideChangeTransitionStart: function () {
        document.querySelector('.slider-timeline-1 .swiper-pagination-custom .swiper-pagination-switch.active').classList.remove("active");
        document.querySelectorAll('.slider-timeline-1 .swiper-pagination-custom .swiper-pagination-switch')[timelineSlider1.realIndex].classList.add("active");
      }
    },
    breakpoints: {
      576: {
        slidesPerView: 'auto',
        spaceBetween: 15,
        autoHeight: false,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        autoHeight: false,
      }
    }
  });

  const timelineSliderSwitches1 = document.querySelectorAll('.slider-timeline-1 .swiper-pagination-custom .swiper-pagination-switch')

  if (timelineSliderSwitches1.length > 0) {
    timelineSliderSwitches1.forEach((sliderSwitch, index) => {
      sliderSwitch.addEventListener('click', function () {
        timelineSlider1.slideTo(index);
      })
    })
  }

  const timelineSlider2 = new Swiper(".slider-timeline-2 .swiper", {
    modules: [Navigation, Controller],
    slidesPerView: "auto",
    autoHeight: true,
    speed: 500,
    navigation: {
      nextEl: ".slider-timeline-2 .swiper-button-next",
      prevEl: ".slider-timeline-2 .swiper-button-prev"
    },
    loop: false,
    on: {
      init: function () {
        document.querySelector('.slider-timeline-2 .swiper-pagination-custom .swiper-pagination-switch').classList.remove("active");
        document.querySelectorAll('.slider-timeline-2 .swiper-pagination-custom .swiper-pagination-switch')[0].classList.add("active");
      },
      slideChangeTransitionStart: function () {
        document.querySelector('.slider-timeline-2 .swiper-pagination-custom .swiper-pagination-switch.active').classList.remove("active");
        document.querySelectorAll('.slider-timeline-2 .swiper-pagination-custom .swiper-pagination-switch')[timelineSlider2.realIndex].classList.add("active");
      }
    },
    breakpoints: {
      576: {
        slidesPerView: 'auto',
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 0
      }
    }
  });

  const timelineSliderSwitches2 = document.querySelectorAll('.slider-timeline-2 .swiper-pagination-custom .swiper-pagination-switch')

  if (timelineSliderSwitches2.length > 0) {
    timelineSliderSwitches2.forEach((sliderSwitch, index) => {
      sliderSwitch.addEventListener('click', function () {
        timelineSlider2.slideTo(index);
      })
    })
  }

  const timelineSlider3 = new Swiper(".slider-timeline-3 .swiper", {
    modules: [Navigation, Controller],
    slidesPerView: "auto",
    autoHeight: true,
    speed: 500,
    navigation: {
      nextEl: ".slider-timeline-3 .swiper-button-next",
      prevEl: ".slider-timeline-3 .swiper-button-prev"
    },
    loop: false,
    on: {
      init: function () {
        document.querySelector('.slider-timeline-3 .swiper-pagination-custom .swiper-pagination-switch').classList.remove("active");
        document.querySelectorAll('.slider-timeline-3 .swiper-pagination-custom .swiper-pagination-switch')[0].classList.add("active");
      },
      slideChangeTransitionStart: function () {
        document.querySelector('.slider-timeline-3 .swiper-pagination-custom .swiper-pagination-switch.active').classList.remove("active");
        document.querySelectorAll('.slider-timeline-3 .swiper-pagination-custom .swiper-pagination-switch')[timelineSlider1.realIndex].classList.add("active");
      }
    },
    breakpoints: {
      576: {
        slidesPerView: 'auto',
        spaceBetween: 15,
        autoHeight: false,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        autoHeight: false,
      }
    }
  });

  const timelineSliderSwitches3 = document.querySelectorAll('.slider-timeline-3 .swiper-pagination-custom .swiper-pagination-switch')

  if (timelineSliderSwitches3.length > 0) {
    timelineSliderSwitches3.forEach((sliderSwitch, index) => {
      sliderSwitch.addEventListener('click', function () {
        timelineSlider3.slideTo(index);
      })
    })
  }

  /* tabs */

  let tabsContainers = document.querySelectorAll('.tabs')
  if (tabsContainers.length > 0) {

    for (let tabsContainer of tabsContainers) {
      let tabsButtons = tabsContainer.querySelectorAll('.tabs-controls__toggle')

      /* Если не задана активная вкладка, то делаем активной 1ю */
      if (!tabsContainer.querySelector('.tabs-controls__toggle.is-active')) {
        tabsButtons[0].classList.add('active')
        if (tabsContainer.querySelector('.tabs-list__item.is-active')) {
          tabsContainer.querySelector('.tabs-list__item.is-active').classList.remove('is-active')
        }
        tabsContainer.querySelector(tabsButtons[0].dataset.tabTarget).classList.add('is-active')
      }

      for (let i = 0; i < tabsButtons.length; i++) {
        tabsButtons[i].addEventListener('click', function (e) {
          e.preventDefault()

          let target = tabsContainer.querySelector(this.dataset.tabTarget);

          if (this.classList.contains('active')) {
            return
          }

          tabsContainer.querySelector('.tabs-controls__toggle.is-active').classList.remove('is-active')
          tabsContainer.querySelector('.tabs-list__item.is-active').classList.remove('is-active')
          this.classList.add('is-active')
          target.classList.add('is-active')
        })
      }
    }
  }

  /* top-filter */

  let topFilters = document.querySelectorAll('.top-filter')

  if (topFilters.length > 0) {
    topFilters.forEach(filter => {

      let filterCurrent = filter.querySelector('.top-filter__selected')
      let filterCurrentValue = filter.querySelector('.top-filter__selected-value')
      let filterOptions = filter.querySelectorAll('.top-filter__item-value')

      filterCurrent.addEventListener('click', () => topFilterToggleDropdown(filter))

      filterOptions.forEach(option => {
        option.addEventListener('click', () => {
          filterCurrent.dataset.value = option.value
          filterCurrentValue.innerText = option.dataset.text
          topFilterToggleDropdown(filter)
        })
      })
    })

    function topFilterToggleDropdown(filter) {
      filter.classList.toggle('is-active')
    }
  }

  /* regional-map */

  let regionalMap = document.querySelector('.js-regional-map')

  if (regionalMap)  {
    let markers = regionalMap.querySelectorAll('.has-mark')
    if (markers.length > 0) {
      markers.forEach(marker => {
        marker.addEventListener('click', function() {
          let targetId = marker.querySelector('g[data-mark]').dataset.mark
          let target = document.getElementById(targetId)

          let current = document.querySelector('.regional-map__office.is-active')
          let currentMarker = document.querySelector('.has-mark.is-active')

          if (current && current !== target) {
            current.classList.remove('is-active')
            currentMarker.classList.toggle('is-active')
          }
          marker.classList.toggle('is-active')
          target.classList.toggle('is-active')

        })
      })
    }
  }

});
